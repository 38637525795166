@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');

body {
  margin: 0px;
}
* {
  margin: 0;
  font-family: 'Poppins', sans-serif;
}
h1 {
  margin: 0px;
}
#root {
  height: 100%;
}
